<template>
   <section class="cont" style="background: #FFFFFF">
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>商城管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/channel_manage/channel_list' }">商品列表</el-breadcrumb-item>
            <el-breadcrumb-item>发布商品</el-breadcrumb-item>
         </el-breadcrumb>
         <el-button  type="primary" plain @click="goBack">返回</el-button>
      </el-row>
      <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
         <el-tab-pane label="基础设置" name="first" class="basics-cont">
            <el-row class="basics-item">
               <span><i>*</i>商品名称：</span>
               <el-input class="width-700" placeholder="请输入商品名称，最多64个字符" v-model="name" ></el-input>
            </el-row>
            <el-row class="basics-item">
               <span>商品编码：</span>
               <el-input class="width-700" v-model="name" ></el-input>
            </el-row>
            <el-row class="basics-item">
               <span><i>*</i>商品分类：</span>
               <el-row class="right-upload">
                  <el-select class="m-left-10 width-200" v-model="type"  placeholder="请选择">
                     <el-option
                         v-for="item in typeList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                  </el-select>
                  <el-select class="m-left-10 width-200" v-model="type"  placeholder="请选择">
                     <el-option
                         v-for="item in typeList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                  </el-select>
                  <el-select class="m-left-10 width-200" v-model="type"  placeholder="请选择">
                     <el-option
                         v-for="item in typeList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                  </el-select>
               </el-row>
            </el-row>
            <el-row class="basics-item">
               <span>商品卖点：</span>
               <el-input class="width-700" v-model="name" ></el-input>
            </el-row>
            <el-row class="basics-item">
               <span><i>*</i>商品主图：</span>
               <el-row class="right-upload m-left-10">
                  <el-upload
                      ref="upload"
                      :limit="1"
                      :show-file-list="true"
                      action="#"
                      :auto-upload="false"
                      list-type="picture-card"
                      :on-change="handleChange"
                      :on-preview="handlePictureCardPreview">
                     <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                     <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
               </el-row>
            </el-row>
            <el-row class="basics-item">
               <span><i>*</i>商品轮播图：</span>
               <el-row class="right-upload m-left-10">
                  <el-upload
                      ref="upload"
                      :limit="1"
                      :show-file-list="true"
                      action="#"
                      :auto-upload="false"
                      list-type="picture-card"
                      :on-change="handleChange"
                      :on-preview="handlePictureCardPreview">
                     <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                     <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
                  <p class="desc">建议尺寸：800*800像素，你可以拖拽图片调整顺序，最多上传8张</p>
               </el-row>
            </el-row>
            <el-row class="basics-item">
               <span><i>*</i>商品视频：</span>
               <el-row class="right-upload m-left-10">
                  <el-upload
                      ref="upload"
                      :limit="1"
                      :show-file-list="true"
                      action="#"
                      :auto-upload="false"
                      list-type="picture-card"
                      :on-change="handleChange"
                      :on-preview="handlePictureCardPreview">
                     <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                     <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
                  <p class="desc">手机端播放，建议时长9-30秒，建议视频宽高比16:9</p>
               </el-row>
            </el-row>
            <el-row class="basics-item">
               <span><i>*</i>自定义分享海报：</span>
               <el-row class="right-upload m-left-10">
                  <el-upload
                      ref="upload"
                      :limit="1"
                      :show-file-list="true"
                      action="#"
                      :auto-upload="false"
                      list-type="picture-card"
                      :on-change="handleChange"
                      :on-preview="handlePictureCardPreview">
                     <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                     <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
                  <p class="desc">自定义商品分享的海报图，留空则默认使用商品封面图，建议尺寸：800*800像素</p>
               </el-row>
            </el-row>
            <el-row class="basics-item">
               <span><i>*</i>商品品牌：</span>
               <el-row class="right-upload">
                  <el-select class="m-left-10 width-200" v-model="type"  placeholder="请选择品牌">
                     <el-option
                         v-for="item in typeList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                  </el-select>
               </el-row>
            </el-row>
            <el-row class="basics-item">
               <span><i>*</i>供货商：</span>
               <el-row class="right-upload">
                  <el-select class="m-left-10 width-200" v-model="type"  placeholder="请选择供货商">
                     <el-option
                         v-for="item in typeList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
                     </el-option>
                  </el-select>
               </el-row>
            </el-row>
         </el-tab-pane>
         <el-tab-pane label="价格库存" name="second" class="stock-cont">
            <el-row class="stock-item">
               <span><i>*</i>商品名称：</span>
               <el-radio-group v-model="radio">
                  <el-radio :label="3">统一规格</el-radio>
                  <el-radio :label="6">多规则</el-radio>
               </el-radio-group>
            </el-row>
            <el-row class="stock-item">
               <span><i>*</i>商品名称：</span>
               <el-row class="table-right">
                  <el-table
                      ref="mainTable"
                      v-loading="loading"
                      :data="[{}]"
                      highlight-current-row
                      style="width: 100%"
                      :stripe="true"
                      >
                     <el-table-column label="规格图片">
                        <template slot-scope="scope">
                           <img v-if="scope.row.coverPath" style="width: 35px;height: 35px" :src="scope.row.coverPath" alt="">
                        </template>
                     </el-table-column>
                     <el-table-column label="市场价(元)">
                        <template>
                           <el-input ></el-input>
                        </template>
                     </el-table-column>
                     <el-table-column label="价格(元)">
                        <template>
                           <el-input ></el-input>
                        </template>
                     </el-table-column>
                     <el-table-column label="成本价(元)">
                        <template>
                           <el-input ></el-input>
                        </template>
                     </el-table-column>
                     <el-table-column label="库存">
                        <template>
                           <el-input ></el-input>
                        </template>
                     </el-table-column>
                     <el-table-column label="体积(m3)">
                        <template>
                           <el-input ></el-input>
                        </template>
                     </el-table-column>
                     <el-table-column label="重量(kg)">
                        <template>
                           <el-input ></el-input>
                        </template>
                     </el-table-column>
                     <el-table-column label="条码">
                        <template>
                           <el-input ></el-input>
                        </template>
                     </el-table-column>
                  </el-table>
               </el-row>
            </el-row>
         </el-tab-pane>
         <el-tab-pane label="商品详情" name="third">角色管理</el-tab-pane>
         <el-tab-pane label="销售设置" name="fourth">定时任务补偿</el-tab-pane>
      </el-tabs>
      <el-row class="save-btn">
         <el-button type="primary" >保存</el-button>
         <el-button type="primary"  plain>取消</el-button>
      </el-row>
   </section>
</template>

<script>
export default {
   data(){
      return{
         activeName: 'first'
      }
   },
   mounted() {
   },
   methods: {

   }
}
</script>

<style lang="scss" scoped>
   .cont{
      .basics-cont{
         margin: 30px 0 0 30px; padding-bottom: 30px; min-width: 1200px;
         .width-700{ width: 700px }
         .basics-item{
            overflow: hidden; line-height: 28px; margin-bottom: 20px;
            span{
               float: left; width: 100px; text-align: right;
               i { font-size: 18px; color: red; margin-right: 5px }
            }
            .el-input{ float: left; margin-left: 10px }
            .right-upload{
               float: left;
               /deep/ .el-upload{ width: 100px; height: 100px; line-height: 100px }
            }
            .desc{ font-size: 12px; color: #999999 }
         }
      }
      .stock-cont{
         margin: 30px 0 0 30px; padding-bottom: 30px;
         .stock-item{
            overflow: hidden;
            span{
               float: left; width: 100px; text-align: right;
               i { font-size: 18px; color: red; margin-right: 5px }
            }
            .el-radio-group{ line-height: 25px; padding: 5px 0 }
            .table-right{ float: left; width: 1000px }
         }
      }
      .save-btn{
         padding-bottom: 30px;
      }
   }
</style>
